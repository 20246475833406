import { toast } from 'react-toastify';
import api from '~/services/api';
import { Produto } from '../../protocols';
import { CalculaICMS } from './CalculaICMS';
import { ValorTabelaEVendaTotal } from './ValorTabelaEVendaTotal';

interface CalculosDaTabelaProps {
  itens: Produto[];
  flg_orgao_publico: boolean;
  tipo_regime: number;
  des_sigla: string;
  cfop: string;
  lastIndex: number;
}

async function getPerFCP(cod_ncm: string, des_sigla: string) {
  try {
    const { data } = await api.get('/item/per-fcp', {
      params: {
        cod_ncm,
        des_sigla,
      },
    });
    return data.data[0]?.per_fcp_icms || 0;
  } catch (error: any) {
    if (error.data && error.data.message) {
      toast.error(error.data.message);
      return 0;
    }
    toast.error(String(error));
    return 0;
  }
}

export async function CalculosDaTabela({
  itens,
  flg_orgao_publico,
  tipo_regime,
  des_sigla,
  cfop,
  lastIndex,
}: CalculosDaTabelaProps): Promise<Produto[]> {
  const formatedItens = [];
  let index = lastIndex <= 0 ? 1 : lastIndex + 1;

  for (let i = 0; i < itens.length; i++) {
    const per_fcp_icms = await getPerFCP(itens[i].cod_ncm, des_sigla);
    // calcular val_tabela_liq
    itens[i].qtd_entrada = itens[i].qtd_total_produto;
    const qtd_total = itens[i].qtd_entrada * itens[i].qtd_embalagem_venda;

    // Inicio campos fixos
    const fixos = {
      num_item: index,
      cfop,
      val_frete: 0,
      val_frete_dest: 0,
      val_icms_deson: 0,
      flg_destaca_st: false,
      per_cofins: 0,
      val_cofins: 0,
      val_base_cofins: 0,
      per_pis: 0,
      val_pis: 0,
      val_base_pis: 0,
      val_base_ipi: 0,
      val_ipi: 0,
      val_isento_ipi: 0,
      val_outros_ipi: 0,
      val_icms_st: 0,
      val_bc_st: 0,
      per_icms: itens[i].val_icms,
      // des_cfop,
      // cfopData,
      // tribData,
      // val_reducao_base_calculo,
      val_fcp_icms: 0,
      // cod_situacao_tributaria,
      // cod_tributacao,
      // qtd_total_produto: qtd_entrada,
      // flg_bonificado: bonificado,
      // tipo_tributacao,
      // val_preco: item.val_preco,
    };
    // Fim campos fixos

    const { val_tabela, val_total } = ValorTabelaEVendaTotal(
      itens[i].val_total_produto,
      itens[i].val_desconto,
      itens[i].val_acrescimo,
      itens[i].val_venda_pdv,
      itens[i].qtd_total_produto,
    );
    const val_tabela_liq =
      qtd_total * (val_tabela - itens[i].val_desconto + itens[i].val_acrescimo);

    const val_tabela_final = val_tabela_liq * 0; // val_ipi é 0 fixado
    const { val_icms, val_bc_icms, val_outros, val_isento, val_fcp_icms } =
      CalculaICMS(
        flg_orgao_publico,
        val_tabela_liq || 0,
        itens[i].val_reducao || 0,
        itens[i].tipo_tributacao,
        itens[i].per_fcp_icms || 0,
        itens[i].cod_situacao_tributaria,
        itens[i].flg_ignora_icms_nf_cli,
        tipo_regime,
        itens[i].flg_calcula_st,
        itens[i].flg_prod_propria,
        itens[i].val_icms,
      );
    const calculados = {
      per_fcp_icms,
      val_tabela,
      val_tabela_liq,
      val_total,
      val_icms,
      val_bc_icms,
      val_outros,
      val_isento,
      val_fcp_icms,
      qtd_total,
      val_tabela_final,
    };
    const newItem = Object.assign(itens[i], fixos, calculados);
    if (flg_orgao_publico) {
      newItem.val_desconto = newItem.val_icms;
      newItem.val_icms = 0;
      newItem.val_bc_icms = 0;
    }
    formatedItens.push(newItem);
    index++;
  }

  return formatedItens;
}
